import ReactDOMServer from 'react-dom/server'
import COMMON from "../store/common/types";
import store from "../store";
import {fetchSearchIndex, search as searchRequest} from "../api/methods/common";


export function openPopUp(pid, content){
  return async dispatch =>{
    dispatch({
      type: COMMON.POPUP.OPEN,
      payload: {pid, content: ReactDOMServer.renderToString(content)}
    })
  }
}

export function closePopUp(){
  return async dispatch =>{
    dispatch({
      type: COMMON.POPUP.CLOSE,
      payload: null
    })
  }
}

const setSearchIndex = (index) => ({
  type: COMMON.SEARCH.SET_INDEX,
  payload: {index}
});

export function getSearchIndex(){
  return async (dispatch) => {
    await fetchSearchIndex()
      .then((response) => {
        dispatch(setSearchIndex(response.data.index));
      })
      .catch((reason) => {
        dispatch(setSearchIndex({
          error: true
        }));
        console.log("Something wrong with fetching index", reason);
      });
  };
}


const setSearchResult = (index) => ({
  type: COMMON.SEARCH.SET_RESULT,
  payload: index
});

const setSearchError = (index) => ({
  type: COMMON.SEARCH.SET_ERROR,
  payload: index
});

export function search(query){
  return async dispatch =>{
    if (!store.getState().common.searchIndex?.error){
      await searchRequest(query)
        .then(response => {
          dispatch(setSearchResult(response.data));
        })
        .catch(reason => {});
    }else{
      dispatch(setSearchError("something went wrong with search"));
    }
  }
}

