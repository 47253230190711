import {Component} from "react";

import Logo from "./Logo";
import Links from "./Links";
import Container from "./Container";
import {connect} from "react-redux";
import {CgClose, CgMenu} from "react-icons/cg";
import {Box, Badge, Flex, Text} from "@chakra-ui/react";
import {baseURL} from "../../api/connector";
import {getSettings} from "../../api/methods/common";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      summaryTable: null,
      active: -1,
      lastUpdate: null
    }
  }

  componentDidMount() {
    getSettings()
      .then(({data}) => {
        this.setState(data);
      })
      .catch((reason) => {
        console.log("Error getting data", reason);
      });
  }

  render() {
    const {isOpen, summaryTable, active, lastUpdate} = this.state;

    if (summaryTable === null) return null;

    return <Container mb={8} px={8} {...this.props} >
      <Logo w="100px"/>
      <Box display={{base: "block", md: "none"}}
           onClick={() => this.setState({isOpen: !isOpen})}>
        {isOpen ? <CgClose/> : <CgMenu/>}
      </Box>
      <Links isOpen={isOpen} links={[
        {
          href: baseURL === "/" ? `/admin` : `${baseURL}/admin`,
          content: 'Админ панель'
        },
        {
          href: summaryTable,
          content: 'Сводная таблица'
        },
        {
          isLink: false,
          isLast: true,
          content: (
                active === null
                  ? <Badge variant="solid" colorScheme="yellow">обновляется</Badge>
                  : (
                    active
                      ? <Badge variant="solid" colorScheme="green">обновлена</Badge>
                      : <Badge variant="solid" colorScheme="red">ошибка</Badge>
                  )
          )
        },
        {
          isLink: false,
          isLast: true,
          content: (
              <Text>Последнее обновление: {(new Date(lastUpdate)).toLocaleString()}</Text>
          )
        }
      ]}/>
    </Container>;
  }
}

function mapStateToProps(state) {
  return {
    role: state.auth.role.id
  }
}

export default connect(mapStateToProps)(Header);
