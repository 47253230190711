import {Fragment} from "react";
import {Box, Stack, Heading} from "@chakra-ui/react";

const MenuItem = ({ children, isLast, href = '#',path, ...rest }) => {
    return (
      <Heading display="block" fontSize="md" as="a" color="gray.600" href={href}
               transition={"all .3s ease-out"}
              _hover={{
                color: "blue.600"
              }}
               {...rest}>
          {children}
      </Heading>
    );
};

function Links({links = [], isOpen}){
    return(
        <Box
            width="100%"
            display={{ base: isOpen ? "flex" : "none", md: "flex" }}
            flexWrap="no-wrap"
        >
            <Stack
                spacing={8}
                align="center"
                mx="auto"
                justify={["center", "space-between", "flex-end", "flex-end"]}
                direction={["column", "row", "row", "row"]}
                pt={[4, 4, 0, 0]}>
                {
                    links.map(
                        ({content, isLink = true, ...link}, index) =>
                            <Fragment key={index}>
                                {
                                    isLink &&
                                    <MenuItem {...link}>
                                        {content}
                                    </MenuItem>
                                }
                            </Fragment>
                    )
                }
            </Stack>
            <Stack spacing={8}
                   align="center"
                   justify={["center", "space-between", "flex-end", "flex-end"]}
                   direction={["column", "row", "row", "row"]}
                   pt={[4, 4, 0, 0]}>
                {
                    links.map(
                        ({content, isLink = true, ...link}, index) =>
                            <Fragment key={index}>
                                {!isLink && <>{content}</>}
                            </Fragment>
                    )
                }
            </Stack>
        </Box>
    );
}

export default Links;
