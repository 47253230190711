import api, { getJwt } from "../connector";

export async function login(search) {
  return api.get(`/auth/google/callback?${search}`);
}

export async function me(userId) {
  const jwt = getJwt();
  return api.get(`/users/${userId}`, {
    headers: { Authorization: `Bearer ${jwt}` },
  });
}
