import logger from 'redux-logger';
import throttle from 'lodash.throttle';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createStore, applyMiddleware, combineReducers } from 'redux';

import auth from "./auth";
import common from "./common";
import { loadState, saveState } from './tools';


const reducers = combineReducers({ auth, common });
const persistedState = loadState();


const store = createStore(
    reducers,
    persistedState,
    process.env.NODE_ENV === 'production'
        ? applyMiddleware(thunkMiddleware)
        : composeWithDevTools(applyMiddleware(thunkMiddleware, logger))
);


store.subscribe(
    throttle(() => {
        const state = store.getState();
        saveState({
            auth: state.auth
        });
    }, 1000)
);

export default store;
