import React, {Fragment} from "react";
import {Table, TableCaption, Thead, Tr, Td, Th, Tbody} from "@chakra-ui/react";

export default function TableComponent({headers, body, caption}) {
  return (
    <Table colorScheme="gray">
      <TableCaption>{caption}</TableCaption>
      <Thead>
        <Tr>
          {
            headers.map((header, index) =>
              <Fragment key={index}><Th>{header}</Th></Fragment>)
          }
        </Tr>
      </Thead>
      <Tbody>
        {
          body.map(({link, data}, index) =>
            <Fragment key={index}>
              <Tr bg={index % 2 === 0 ? "gray.100" : "transparent"}
                  _hover={{ bg: "gray.400" }} sx={{cursor: "pointer", transition: "all .3s ease"}}
                  onClick={() => window.open(link, '_blank').focus()}
              >
                {
                  data.map((text, i) => <Fragment key={i}><Td>{text}</Td></Fragment>)
                }
              </Tr>
            </Fragment>
          )
        }
      </Tbody>
    </Table>
  );
}
