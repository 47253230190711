import {connect} from "react-redux";
import React, {Component} from "react";
import {AiOutlineSearch} from "react-icons/ai";
import {
  Container, InputGroup,
  InputLeftElement, Input, Spinner
} from "@chakra-ui/react"

import Header from "../components/Header";
import {getProjects} from "../api/methods/common";
import Table from "../components/Table";
import {search, getSearchIndex} from "../actions/common";


class IndexPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: {},
      query: ""
    }
  }

  componentDidMount() {
    const {dispatch} = this.props;
    dispatch(getSearchIndex());

    getProjects()
      .then(response => {
        console.log(response.data);
        let projects = {};
        response.data.forEach(p => projects[p.id] = p);
        this.setState({projects})
      })
      .catch(reason => {
        console.log();
      })
  }

  render() {
    const {projects, query} = this.state;
    const {dispatch, searchResults} = this.props;

    return (
      <>
        <Header/>
        <Container pt="140px" maxW="container.xl">
          <Container mb="40px">
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                h="100%"
                color="gray.300">
                <AiOutlineSearch style={{height: "20px", width: "20px"}}/>
              </InputLeftElement>
              <Input placeholder="search..."
                     onChange={(e) => {
                       this.setState({query: e.target.value});
                       dispatch(search(this.state.query))
                     }}
                     size="lg" colorScheme="blue" variant="filled"/>
            </InputGroup>
          </Container>

          {
            (query.length > 3 ? searchResults : Object.values(projects)).length === 0
              ? <div style={{display: "flex", justifyContent: "center", marginTop: "140px"}}>
                <Spinner size="xl"/>
              </div>
              : <Table headers={["Проект", "Описание"]}
                       caption="Все проекты доступные для редактирования"
                       body={
                         (query.length > 3 ? searchResults : Object.values(projects))
                           .map(p => ({
                             link: p.link,
                             data: [p.title, p.description]
                           }))}/>
          }
        </Container>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    searchIndex: state.common.searchIndex,
    searchResults: state.common.searchResults
  }
}

export default connect(mapStateToProps)(IndexPage);
