import {
  Box, Button,
  Heading, useColorModeValue,
} from '@chakra-ui/react'
import Card from "../components/Card";
import {baseURL} from "../api/connector";
import {AiOutlineGoogle} from "react-icons/ai";


export default function LoginPage({}){
  return (
    <Box
      bg={useColorModeValue('gray.50', 'inherit')}
      minH="100vh"
      sx={{position: "relative"}}
      px={{ base: '4', lg: '8' }}
    >
      <Box maxW="md" mx="auto" sx={{
        position: "absolute",
        top: "50%", left: "50%",
        transform: "translate(-50%, -50%)"
      }}>
        <Card>
          <Heading textAlign="center" size="lg" fontWeight="bold"
                   mt="4" mb="8" align="center">
            Sign in to your account
          </Heading>
          <Button leftIcon={<AiOutlineGoogle />} width="100%"
                  colorScheme="teal" variant="solid"
                  onClick={() =>
                    (window.location = baseURL === "/" ? `/connect/google` : `${baseURL}/connect/google`)
                  }>
            Login with Google
          </Button>
        </Card>
      </Box>
    </Box>
  );
}
