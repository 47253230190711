import {Component} from 'react';
import {ChakraProvider} from "@chakra-ui/react"
import { connect } from "react-redux";
import {PublicRoute, PrivateRoute} from "react-private-public-route";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";


import Login from "./pages/Login";
import IndexPage from "./pages/IndexPage";
import AuthCallback from "./pages/AuthCallback";


class App extends Component {

  render() {
    const {isLogged} = this.props;

    return (
      <ChakraProvider>
        <Router>
          <Switch>
            <PublicRoute exact path="/login" component={Login}/>
            <PublicRoute path="/login/callback" component={AuthCallback}/>
            <PrivateRoute isAuthenticated={isLogged} exact path="/"
                          component={IndexPage} redirect="/login"/>
            <PrivateRoute
              isAuthenticated={isLogged}
              redirect="/login"
              component={() => (
              <div>
                not found page
              </div>
            )}/>
          </Switch>
        </Router>
      </ChakraProvider>
    );
  }
}

function mapStateToProps(state){
  return {
    isLogged: state.auth.isLoggedIn
  }
}

export default connect(mapStateToProps)(App);
