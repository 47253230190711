import { connect } from "react-redux";
import React, { Component } from "react"
import {Center, Spinner} from "@chakra-ui/react";
import {withRouter} from "react-router-dom";

import {login} from "../actions/auth";


class AuthCallback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: {}
    }
  }

  componentDidMount(){
    const {location, history, dispatch} = this.props;

    dispatch(login(location.search))
      .then(() => {
        if (!this.props.isLogged){
          history.push('/login');
        }else {
          history.push('/');
        }
      });
  }

  render(){
    return (
      <Center h="100vh">
        <Spinner size="xl" />
      </Center>
    )
  }
}

function mapStateToProps(state){
  return {
    isLogged: state.auth.isLoggedIn
  }
}

export default connect(mapStateToProps)(withRouter(AuthCallback));
