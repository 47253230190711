import { SIGN, REGISTER } from "../store/auth/types";
import { login as loginRequest, register as registerRequest } from "../api/methods/auth";

export function signInAction(data) {
    return {
        type: SIGN.IN,
        payload: data,
    };
}

function signOutAction(signError = {}) {
    return {
        type: SIGN.OUT,
        payload: { signError },
    };
}


export function login(secret) {
    return async (dispatch) => {
        dispatch(signOutAction());
        await loginRequest(secret)
          .then((response) => {
              dispatch(signInAction(response.data));
          })
          .catch((reason) => {
              dispatch(signOutAction(reason));
          });
    };
}

export function logout() {
    return signOutAction();
}
