import COMMON from "./types";

const defaultState = {
  popUpId: -1,
  searchIndex: null,
  searchError: "",
  searchResults: [],
  popUpContent: null,
};

function CommonReducer(state = defaultState, action){
  switch (action.type) {
    case COMMON.POPUP.OPEN:{
      return {
        ...state,
        popUpId: action.payload.pid,
        popUpContent: action.payload.content
      }
    }
    case COMMON.POPUP.CLOSE:{
      return {
        ...state,
        popUpId: null
      }
    }
    case COMMON.SEARCH.SET_INDEX: {
      return {
        ...state,
        searchIndex: action.payload.index
      }
    }
    case COMMON.SEARCH.SET_RESULT: {
      return {
        ...state,
        searchResults: action.payload
      }
    }
    case COMMON.SEARCH.SET_ERROR: {
      return {
        ...state,
        searchError: action.payload
      }
    }
    default:
      return state;
  }
}

export default CommonReducer;
