import api, { getJwt } from "../connector";

export function getProjects(){
  const jwt = getJwt();
  return api.get(`/projects`, {
    headers: { Authorization: `Bearer ${jwt}` },
  });
}

export function fetchSearchIndex(){
  const jwt = getJwt();
  return api.get(`/search-index`, {
    headers: { Authorization: `Bearer ${jwt}` },
  });
}

export function search(query){
  const jwt = getJwt();
  return api.get(`/projects?title_contains=${query}`, {
    headers: { Authorization: `Bearer ${jwt}` },
  });
}

export function getSettings(){
  const jwt = getJwt();
  return api.get(`/settings`, {
    headers: { Authorization: `Bearer ${jwt}` },
  });
}
