import { Heading } from "@chakra-ui/react";

export default function Logo(props) {
    //<Box>
    return (
        <Heading fontSize="lg" color="blue.400" fontWeight="bold" {...props}>
             Projects
        </Heading>
    );
}
