import { REGISTER, SIGN } from "./types";

let initialState = {
  isLoggedIn: false,
  userId: null,
  role: {},
  name: null,
  jwt: "",
};

function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case SIGN.OUT: {
      return {
        ...state,
        ...initialState,
        signError: action.payload.signError,
      };
    }
    case SIGN.IN: {
      return {
        ...state,
        isLoggedIn: true,
        jwt: action.payload.jwt,
        name: action.payload.name,
        role: action.payload.user.role,
        userId: action.payload.user.id,
      };
    }
    default:
      return state;
  }
}

export default AuthReducer;
