import {Flex} from "@chakra-ui/react";

export default function HeaderContainer({children, ...props}){
    return (
        <Flex
            as="nav"
            align="center"
            justify={["space-between", "space-between", "left", "left"]}
            w="100%"
            py={4}
            bg="white"
            color="gray.900"
            boxShadow="rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;"
            {...props}>
            {children}
        </Flex>
    );
}